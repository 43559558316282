export const team = [
    {
        "id": 1,
        "name": "Gilberto",
        "lastname": "Lara",
        "job": {
            "es": "CEO",
            "en": "CEO"
        },
        "photo": "team/ale.jpg",
        "linkedin": "https://www.linkedin.com/in/galara",
        "status": 2
    },
    {
        "id": 2,
        "name": "Ethel",
        "lastname": "de Kuri",
        "job": {
            "es": "CCO",
            "en": "CCO"
        },
        "photo": "team/ale.jpg",
        "linkedin": "https://www.linkedin.com/in/ethel-jeannette-monge-de-kuri-a091a637",
        "status": 2
    },
    {
        "id": 3,
        "name": "Alejandra",
        "lastname": "Muñoz",
        "job": {
            "es": "Project Manager",
            "en": "Project Manager"
        },
        "photo": "team/ale.jpg",
        "linkedin": "https://www.linkedin.com/in/alejandra-mu%C3%B1oz-6447a3125",
        "status": 1
    },
    {
        "id": 4,
        "name": "Gerardo",
        "lastname": "Amaya",
        "job": {
            "es": "Project Manager",
            "en": "Project Manager"
        },
        "photo": "team/gerardo.jpg",
        "linkedin": "https://www.linkedin.com/in/gerardo-amaya-sv",
        "status": 2
    },
    {
        "id": 5,
        "name": "Juan Pablo",
        "lastname": "Chinchilla",
        "job": {
            "es": "Project Manager",
            "en": "Project Manager"
        },
        "photo": "team/gilberto_lara.jpg",
        "linkedin": "https://www.linkedin.com/in/juan-pablo-chinchilla-8861b726",
        "status": 2
    },
    {
        "id": 6,
        "name": "Francisco",
        "lastname": "Lara",
        "job": {
            "es": "Project Manager",
            "en": "Project Manager"
        },
        "photo": "team/francisco.jpg",
        "linkedin": "https://www.linkedin.com/in/francisco-lara-77777a127",
        "status": 1
    },
    {
        "id": 7,
        "name": "César",
        "lastname": "Preza",
        "job": {
            "es": "Teaching & Learning Manager",
            "en": "Teaching & Learning Manager"
        },
        "photo": "team/cesar.jpg",
        "linkedin": "https://www.linkedin.com/in/cesar-preza",
        "status": 1
    },
    {
        "id": 8,
        "name": "Gerardo",
        "lastname": "Kuri",
        "job": {
            "es": "Lead Programmer",
            "en": "Lead Programmer"
        },
        "photo": "team/gerardo_kuri.jpg",
        "linkedin": "https://www.linkedin.com/in/gerardokuri",
        "status": 2
    },
    {
        "id": 9,
        "name": "Andrea",
        "lastname": "Amaya",
        "job": {
            "es": "Content Manager",
            "en": "Content Manager"
        },
        "photo": "team/andrea.png",
        "linkedin": "https://www.linkedin.com/in/amayaandrea",
        "status": 2
    },
    {
        "id": 10,
        "name": "Victor",
        "lastname": "López",
        "job": {
            "es": "Lead Programmer",
            "en": "Lead Programmer"
        },
        "photo": "team/victor.jpg",
        "linkedin": "https://www.linkedin.com/in/vlopezsv",
        "status": 1
    },
    {
        "id": 11,
        "name": "Jorge",
        "lastname": "Rodríguez",
        "job": {
            "es": "Blockchain Developer",
            "en": "Blockchain Developer"
        },
        "photo": "team/george.png",
        "linkedin": "https://www.linkedin.com/in/jorgerodriguezsv",
        "status": 2
    },
    {
        "id": 12,
        "name": "Andrés",
        "lastname": "Alfaro",
        "job": {
            "es": "FullStack Developer",
            "en": "FullStack Developer"
        },
        "photo": "team/andres.jpg",
        "linkedin": "https://www.linkedin.com/in/andres-alfaro-parada",
        "status": 1
    },
    {
        "id": 13,
        "name": "Emerson",
        "lastname": "López",
        "job": {
            "es": "Frontend Developer",
            "en": "Frontend Developer"
        },
        "photo": "team/emerson.jpg",
        "linkedin": "https://www.linkedin.com/in/emersonlopez21",
        "status": 1
    },
    {
        "id": 14,
        "name": "Gabriela",
        "lastname": "Miranda",
        "job": {
            "es": "Backend Developer",
            "en": "Backend Developer"
        },
        "photo": "team/gabi.jpg",
        "linkedin": "https://www.linkedin.com/in/gabrielamirandame",
        "status": 1
    },
    {
        "id": 15,
        "name": "Manuel",
        "lastname": "García",
        "job": {
            "es": "Web Developer",
            "en": "Web Developer"
        },
        "photo": "team/manuel.jpg",
        "linkedin": "https://www.linkedin.com/in/manuelgarciasv",
        "status": 1
    },
    {
        "id": 16,
        "name": "Hilda",
        "lastname": "Canizález",
        "job": {
            "es": "Digital Strategist",
            "en": "Digital Strategist"
        },
        "photo": "team/hilda.jpg",
        "linkedin": "https://www.linkedin.com/in/hilda-caniz%C3%A1lez-218aa6234",
        "status": 1
    },
    {
        "id": 22,
        "name": "Daniela",
        "lastname": "Kuri",
        "job": {
            "es": "Digital Strategist",
            "en": "Digital Strategist"
        },
        "photo": "team/daniela.jpg",
        "linkedin": "https://www.linkedin.com/in/daniela-kuri",
        "status": 1
    },
    {
        "id": 17,
        "name": "Mauricio",
        "lastname": "Loza",
        "job": {
            "es": "Senior Designer",
            "en": "Senior Designer"
        },
        "photo": "team/mau.jpg",
        "linkedin": "https://www.linkedin.com/in/mauricio-loza-52731a185",
        "status": 2
    },
    {
        "id": 18,
        "name": "Rony",
        "lastname": "Hernández",
        "job": {
            "es": "Digital Animator& Illustrator",
            "en": "Digital Animator& Illustrator"
        },
        "photo": "team/rony.jpg",
        "linkedin": "https://www.linkedin.com/in/rony-hernandez-596002180",
        "status": 2
    },
    {
        "id": 19,
        "name": "Christian",
        "lastname": "Deleón",
        "job": {
            "es": "Graphic & UX/UI Designer",
            "en": "Graphic & UX/UI Designer"
        },
        "photo": "team/christian.jpg",
        "linkedin": "https://www.linkedin.com/in/christian-dele%C3%B3n-94465b162",
        "status": 1
    },
    {
        "id": 19,
        "name": "Néstor",
        "lastname": "Maldonado",
        "job": {
            "es": "Graphic Designer & Multimedia",
            "en": "Graphic Designer & Multimedia"
        },
        "photo": "team/nestor.jpg",
        "linkedin": "https://www.linkedin.com/in/nestormorenom",
        "status": 1
    },
    {
        "id": 23,
        "name": "Natalie",
        "lastname": "Calona",
        "job": {
            "es": "Digital Strategist",
            "en": "Digital Strategist"
        },
        "photo": "team/calona.jpg",
        "linkedin": "https://www.linkedin.com/in/nataliecalonaguardado",
        "status": 1
    },
    {
        "id": 24,
        "name": "Carlos",
        "lastname": "González",
        "job": {
            "es": "Graphic Designer & Digital Animation",
            "en": "Graphic Designer & Digital Animation"
        },
        "photo": "team/carlos.jpg",
        "linkedin": "https://www.linkedin.com/in/carlos-e-gonz%C3%A1lez",
        "status": 1
    },
    {
        "id": 25,
        "name": "Nathaly",
        "lastname": "Cerón",
        "job": {
            "es": "Communications & PR",
            "en": "Communications & PR"
        },
        "photo": "team/ceron.jpg",
        "linkedin": "https://www.linkedin.com/in/nathaly-cer%C3%B3n-b62bb3245",
        "status": 1
    },
    {
        "id": 21,
        "name": "Kevin",
        "lastname": "Gutiérrez",
        "job": {
            "es": "Technical Support",
            "en": "Technical Support"
        },
        "photo": "team/kevin.jpg",
        "linkedin": "https://www.linkedin.com/in/kevin-gutierrez-644b17234",
        "status": 1
    }
]
